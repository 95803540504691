<template>
  <div class="wrap">
    <Nav title="Danh sách thảo luận nhóm bộ sưu tập " />
    <List url="/rooms/lists" :defaultParams="{ group: 1 }">
      <template v-slot="{ item }">
        <UserItem
          :img="item.userimg"
          class="useritem_wrap"
          :text="item.username"
          @click="handleClick(item)"
        />
      </template>
    </List>
  </div>
</template>

<script>
import Nav from "./components/nav.vue";
import List from "@/components/List.vue";
import UserItem from "./components/userItem.vue";
export default {
  components: { Nav, List, UserItem },
  methods: {
    handleClick(item) {
      this.$router.push(`/ChatRoom?id=${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  color: #333;
  font-size: 14px * 2;
  margin-top: 52px * 2;
}
.wrap{
  min-height: 100vh;
  background: #fff;
}
.useritem_wrap {
  margin: 20px 30px 0;
}
</style>